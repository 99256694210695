import * as Yup from "yup";
import { commonStringSchema } from "../../utils/commonFieldSchema";

const QUESTION_TYPE = {
  TEXT: "TEXT",
  RATING: "RATING",
  MULTI_SELECT: "MULTI SELECT",
  SINGLE_SELECT: "SINGLE SELECT",
  YES_NO: "Yes/No with Justification",
};

export const declarationValidationSchema = Yup.object().shape({
  declarations: Yup.array().of(
    Yup.object().shape({
      questionnaireQuestions: Yup.array().of(
        Yup.object().shape({
          answer: Yup.lazy((_, { parent: question, ...ctx }) => {
            try {
              let schema = Yup.mixed().nullable();

              if (question.questionTypeName === QUESTION_TYPE.TEXT) {
                schema = Yup.string()
                  .required("Field is required")
                  .typeError("Field is required");
              }

              if (question.questionTypeName === QUESTION_TYPE.SINGLE_SELECT) {
                schema = Yup.string()
                  .required("Field is required")
                  .typeError("Please select an option.");
              }

              if (question.questionTypeName === QUESTION_TYPE.MULTI_SELECT) {
                schema = Yup.array()
                  .of(Yup.string())
                  .required("Field is required")
                  .typeError("Please select options.");

                const { noOfAnswersRequired = 1 } =
                  question?.answerChoice || {};

                schema = schema.min(
                  noOfAnswersRequired,
                  `Please select at least ${noOfAnswersRequired} options.`
                );
              }

              if (question.questionTypeName === QUESTION_TYPE.RATING) {
                const { minScale = 1, maxScale = 9999 } =
                  question.answerChoice || {};
                schema = Yup.array()
                  .of(
                    Yup.object().shape({
                      rating: Yup.number()
                        .min(minScale, `Min value is ${minScale}`)
                        .max(maxScale, `Max value is ${maxScale}`),
                    })
                  )
                  .required("Field is required")
                  .typeError("Rating is required.");
              }

              if (question.questionTypeName === QUESTION_TYPE.YES_NO) {
                schema = Yup.object().shape({
                  answeroption: Yup.string()
                    .nullable()
                    .required("Field is required")
                    .typeError("Field is required"),
                  justification: Yup.string()
                    .nullable()
                    .when("answeroption", {
                      is: (answeroption) => {
                        if (answeroption === "No") {
                          return true;
                        }
                        return false;
                      },
                      then: (schema) =>
                        schema
                          .required("Field is required")
                          .typeError("Field is required"),
                    }),
                });
              }
              return schema;
            } catch (err) {
              console.log(question.candidateQuestionnairesQuestionsId, err);
            }
          }),
        })
      ),
    })
  ),
});

export const refereeValidationSchema = Yup.object().shape({
  declarations: Yup.array().of(
    Yup.object().shape({
      questionnaireQuestions: Yup.array().of(
        Yup.object().shape({
          answer: Yup.lazy((_, { parent: question, ...ctx }) => {
            try {
              let schema = Yup.mixed().nullable();

              if (question.questionTypeName === QUESTION_TYPE.TEXT) {
                // schema = Yup.string()
                //   .required("Field is required")
                //   .typeError("Field is required");
                schema = commonStringSchema(
                  question.isMandatory,
                  "Field is required",
                  1000
                );
              }

              if (question.questionTypeName === QUESTION_TYPE.SINGLE_SELECT) {
                // schema = Yup.string()
                //   .required("Field is required")
                //   .typeError("Please select an option.");
                schema = commonStringSchema(
                  question.isMandatory,
                  "Please select an option.",
                  1000
                );
              }

              if (question.questionTypeName === QUESTION_TYPE.MULTI_SELECT) {
                // schema = Yup.array()
                //   .of(Yup.string())
                //   .required("Field is required")
                //   .typeError("Please select options.");
                schema = Yup.array()
                  .nullable()
                  .of(
                    commonStringSchema(
                      question.isMandatory,
                      "Please select an option.",
                      1000
                    )
                  )
                  .when([], {
                    is: () => question.isMandatory,
                    then: (schema) =>
                      schema.required("Please select an option."),
                  });

                const { noOfAnswersRequired = 1 } =
                  question?.answerChoice || {};

                schema = schema.min(
                  noOfAnswersRequired,
                  `Please select at least ${noOfAnswersRequired} options.`
                );
              }

              if (question.questionTypeName === QUESTION_TYPE.RATING) {
                const { minScale = 1, maxScale = 9999 } =
                  question.answerChoice || {};
                schema = Yup.array()
                  .of(
                    Yup.object().shape({
                      rating: Yup.number()
                        .min(minScale, `Min value is ${minScale}`)
                        .max(maxScale, `Max value is ${maxScale}`),
                    })
                  )
                  .when([], {
                    is: () => question.isMandatory,
                    then: (schema) =>
                      schema
                        .required("Field is required")
                        .typeError("Rating is required."),
                  });
              }

              if (question.questionTypeName === QUESTION_TYPE.YES_NO) {
                schema = Yup.object()
                  .shape({
                    answeroption: Yup.string()
                      .nullable()
                      .required("Field is required")
                      .typeError("Field is required"),
                    justification: Yup.string()
                      .nullable()
                      .when("answeroption", {
                        is: (answeroption) => {
                          if (answeroption === "No") {
                            return true;
                          }
                          return false;
                        },
                        then: (schema) =>
                          schema
                            .required("Field is required")
                            .typeError("Field is required"),
                      }),
                  })
                  .when([], {
                    is: () => question.isMandatory,
                    then: (schema) =>
                      schema
                        .required("Field is required")
                        .typeError("Field is required"),
                  });
              }
              return schema;
            } catch (err) {
              console.log(question.candidateQuestionnairesQuestionsId, err);
            }
          }),
        })
      ),
      verifierDetails: Yup.object().shape({
        verifierFullName: Yup.string().trim().required("Field is required"),
        verifierDesignation: Yup.string().trim().required("Field is required"),
        verifierDepartment: Yup.string().trim().required("Field is required"),
        verifierPhone: Yup.string()
          .phone("*", "Please enter a valid phone number")
          .required("A phone number is required"),
        verifierWorkEmail: Yup.string()
          .trim()
          .email()
          .required("Field is required"),
        verifierValidationDate: Yup.date().required("Field is required"),
      }),
    })
  ),
});
