import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CandidatesTable from "./CandidatesTable";
import { getLoggedInUserHrOrganizationId } from "../../utils/UserHelper";
import TeamRangeSelection from "../common/TeamRangeSelection";
import { useDispatch, useSelector } from "react-redux";
import {
  checkActionPermission,
  checkPagePermission,
} from "../../utils/CheckPageAccess";
import permissionKey from "../constants/permissionKey";
import ScrollableGrid from "../../common/ScrollableGrid";
import PanelCard from "../../common/cards/PanelCard";
import AddMultipleCandidatesModal from "./CandidatesTable/Modals/AddMultipleCandidatesModal";
import { getFailedToRegisterCandidates } from "../../store/actions/hrActions";
import { hrCandidatesByOrgId } from "../../store/actions/hrActions";
import {
  CUSTOM_DATE_FILTER_ERROR,
  GET_HR_CANDIDATES,
  GET_TEAMS_BY_ORG_ID_DATA,
  PREVIOUS_HR_CANDIDATES_API_PARAMS,
} from "../../store/actions/actionTypes";
import { setToastNotification } from "../../store/actions/toastNotificationActions";
import { ERROR } from "../../store/constant";
import moment from "moment";
import { ClearOutlined, SearchOutlined } from "@mui/icons-material";
import { debounce, isEqual } from "lodash";
import { getCurrentFileNameAndFunction } from "../../utils/getCurrentFileNameAndFunction";
import { rangeSelectors } from "../constants/filterData";
import CandidateCreationOptionsComponent from "../../common/CandidateCreationOptionsComponent";
import SortByHRCandidatesComponent from "./components/SortByHRCandidatesComponent";

const CandidatesPage = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterCandidates, setFilteredCandidates] = useState("");
  const { previousHrCandidatesApiParams } = useSelector((state) => state.hr);
  const hrTableSortableColumnsRef = useRef([]);

  const loggedInUser = useSelector((state) => state.authorization);

  const [addMultipleCandidatesModal, setAddMultipleCandidatesModal] =
    useState(false);

  useEffect(() => {
    return () => {
      //to reset teams lists by org id state for updating team name in table
      dispatch({
        type: GET_TEAMS_BY_ORG_ID_DATA,
        payload: "reset",
      });

      //to reset candidates lists
      dispatch({
        type: GET_HR_CANDIDATES,
        payload: {
          candidatesLists: [],
          totalCandidateCaseCount: 0,
          allCandidateDataForReport: [],
        },
      });

      //to clear from date greater than to date error message
      dispatch({
        type: CUSTOM_DATE_FILTER_ERROR,
        payload: "",
      });

      //to clear already saved params
      dispatch({
        type: PREVIOUS_HR_CANDIDATES_API_PARAMS,
        payload: null,
      });
    };
  }, []);

  const hrCandidateCreateAccess = useMemo(
    () =>
      checkActionPermission(
        permissionKey.hrInviteCandidate,
        loggedInUser.permissions
      ) &&
      checkPagePermission("/hr/candidates/create", loggedInUser.permissions),
    [loggedInUser.permissions]
  );

  useEffect(() => {
    onFetchCandidates();
  }, [searchParams]);

  const onFilterChange = (finalFilters) => {
    if (!finalFilters) return;

    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "onFilterChange"
    );

    dispatch(
      hrCandidatesByOrgId(
        getLoggedInUserHrOrganizationId(),
        finalFilters,
        logDetails
      )
    );
  };

  const debouncedSearch = useCallback(debounce(onFilterChange, 500), [
    dispatch,
  ]);

  const onFetchCandidates = useCallback(
    (paramFilters) => {
      let initialFilters = {
        hrTeamId: searchParams.get("hrTeamId") || "All",
        fromDate:
          searchParams.get("fromDate") || rangeSelectors[0]?.dates?.fromDate,
        toDate: searchParams.get("toDate") || rangeSelectors[0]?.dates?.toDate,
        searchText: searchParams.get("searchText") || "",
        periodCode: searchParams.get("periodCode") || rangeSelectors[0]?.label,
        rangeValue: searchParams.get("rangeValue") || 0,
        verificationProcessId:
          searchParams.get("verificationProcessId") || "2,3",
        verificationResultId:
          searchParams.get("verificationResultId") || "1,2,3,4",
        pageNumber: +searchParams.get("pageNumber") || 1,
        orderBy: searchParams.get("orderBy") || "createdAt",
        orderDirection: searchParams.get("orderDirection") || "desc",
        processStatusSwitch: searchParams.get("processStatusSwitch") || "1",
        resultStatusSwitch: searchParams.get("resultStatusSwitch") || "0",
      };

      let finalFilters = paramFilters || initialFilters;

      setFilteredCandidates(finalFilters?.searchText);

      //setting params to url
      setSearchParams((prevParams) => {
        return new URLSearchParams({
          ...Object.fromEntries(prevParams.entries()),
          ...finalFilters,
        });
      });

      delete finalFilters?.rangeValue;

      if (!finalFilters?.orderBy || !finalFilters?.orderDirection) {
        delete finalFilters?.orderBy;
        delete finalFilters?.orderDirection;
      }

      console.log("00000-check", previousHrCandidatesApiParams, finalFilters);

      const from = moment(finalFilters?.fromDate);
      const to = moment(finalFilters?.toDate);

      // Compare the dates
      if (from > to) {
        dispatch(
          setToastNotification(
            ERROR,
            "Please correct custom range dates before proceeding"
          )
        );
        dispatch({
          type: CUSTOM_DATE_FILTER_ERROR,
          payload: "From date should be smaller than To date",
        });
      } else if (!isEqual(previousHrCandidatesApiParams, finalFilters)) {
        dispatch({
          type: CUSTOM_DATE_FILTER_ERROR,
          payload: "",
        });
        debouncedSearch(finalFilters);
      }
    },
    [searchParams, previousHrCandidatesApiParams]
  );

  const handleModalOpen = () => {
    setAddMultipleCandidatesModal(true);

    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleModalOpen"
    );

    dispatch(getFailedToRegisterCandidates(logDetails));
  };

  const handleOnChangeSearch = (val) => {
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        searchText: val,
      });
    });

    setFilteredCandidates(val);
  };
  //   {
  //     headerName: "VERIFICATION RESULT",
  //     field: "verificationResultStatusName",
  //   },
  //   {
  //     headerName: "PROCESS STATUS",
  //     field: "verificationProcessStatusName",
  //   },
  //   {
  //     headerName: "CANDIDATE NAME",
  //     field: "candidateName",
  //   },

  //   {
  //     headerName: "HIRING COUNTRY",
  //     field: "hiringCountryName",
  //   },

  //   {
  //     headerName: "REGISTERED USER",
  //     field: "userName",
  //   },
  //   {
  //     headerName: "TEAM NAME",
  //     field: "hrTeamName",
  //   },
  //   {
  //     headerName: "REG DATE",
  //     field: "createdAt",
  //   },
  // ];

  return (
    <ScrollableGrid>
      <Grid
        container
        spacing={2}
        sx={{ height: "90vh" }}
        mt={{ xs: 8, md: -2 }}
      >
        <Grid item md={2} sm={12} xs={12}>
          <PanelCard>
            <TeamRangeSelection />
          </PanelCard>
        </Grid>
        <Grid item md={10} sm={12} xs={12}>
          <PanelCard>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
                justifyContent: "space-between",
                mt: 1,
                mb: 1,
              }}
            >
              <Box>
                <Typography variant="h4" sx={{ marginBottom: "10px" }}>
                  Candidates
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 1,
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                <Box>
                  {hrCandidateCreateAccess && (
                    <CandidateCreationOptionsComponent
                      handleModalOpen={handleModalOpen}
                    />
                  )}
                </Box>
                <Box width={{ xs: "100%", sm: "auto" }}>
                  <TextField
                    value={filterCandidates}
                    placeholder="Search..."
                    type="text"
                    fullWidth={true}
                    size="small"
                    variant="outlined"
                    onChange={(e) => handleOnChangeSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined style={{ marginLeft: "0.5rem" }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <ClearOutlined
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOnChangeSearch("")}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                {/*sort by hr candidates component */}

                <SortByHRCandidatesComponent
                  sortableColumns={hrTableSortableColumnsRef.current}
                />
              </Box>
            </Box>
            <CandidatesTable
              onFetchCandidates={onFetchCandidates}
              hrTableSortableColumnsRef={hrTableSortableColumnsRef}
            />
          </PanelCard>
        </Grid>
      </Grid>
      {addMultipleCandidatesModal && (
        <AddMultipleCandidatesModal
          open={addMultipleCandidatesModal}
          handleClose={() => setAddMultipleCandidatesModal(false)}
          resetOpsCandidatesTableFilters={() =>
            onFetchCandidates({
              hrTeamId: "All",
              fromDate: rangeSelectors[0]?.dates?.fromDate,
              toDate: rangeSelectors[0]?.dates?.toDate,
              searchText: "",
              periodCode: rangeSelectors[0]?.label,
              rangeValue: 0,
              verificationProcessId: "2,3",
              verificationResultId: "3",
              pageNumber: 1,
            })
          }
        />
      )}
    </ScrollableGrid>
  );
};

export { CandidatesPage };
