import { Grid, Box } from "@mui/material";
import { checkPagePermission } from "../../../../../utils/CheckPageAccess";

const ChecksCandidatesViewSelection = ({
  searchParams,
  handleOpsTableViewChange,
}) => {
  return (
    <Box sx={{ flexWrap: 1, display: "flex" }}>
      <Box
        p={1}
        sx={{
          background: (theme) =>
            searchParams.get("toggledOpsTableView") === "candidates"
              ? theme.palette.primary.main
              : "none",
          color:
            searchParams.get("toggledOpsTableView") === "candidates"
              ? "#FFF"
              : "none",
          borderRadius: "10px 0 0 10px",
          border: (theme) => `1px solid ${theme.palette.grey[400]}`,
          cursor: "pointer",
          fontWeight: "550",
        }}
        onClick={() => handleOpsTableViewChange("candidates")}
      >
        Candidates
      </Box>
      <Box
        p={1}
        sx={{
          background: (theme) =>
            searchParams.get("toggledOpsTableView") === "checks"
              ? theme.palette.primary.main
              : "none",
          color:
            searchParams.get("toggledOpsTableView") === "checks"
              ? "#FFF"
              : "none",
          borderRadius: "0 10px 10px 0",
          border: (theme) => `1px solid ${theme.palette.grey[400]}`,
          borderLeft: "none",
          cursor: "pointer",
          fontWeight: "550",
        }}
        onClick={() => handleOpsTableViewChange("checks")}
      >
        Checks
      </Box>
    </Box>
  );
};
export default ChecksCandidatesViewSelection;
