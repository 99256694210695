import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

const ReferenceCheckCard = () => {
  const theme = useTheme(); // Get theme for styling

  // Render the Reference Check card
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main, // Blue background color
        borderRadius: "20px",
        padding: { xs: "16px", sm: "24px" }, // Responsive padding
        width: { xs: "100%" }, // Responsive width
        margin: "auto",
        color: "#ffffff", // White text
        textAlign: "center",
        alignItems: "flex-start",
        mb: 1,
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          backgroundColor: theme.palette.grey[100], // Light gray background
          color: "#000", // Black text
          padding: "8px 16px",
          borderRadius: "20px",
          fontWeight: "bold",
          marginBottom: "16px",
        }}
      >
        Reference Check
      </Typography>
      <Typography
        variant="body1"
        color={theme.palette.grey[100]}
        fontWeight="bold"
      >
        Please provide your assessment of the candidate's performance and work
        ethic.
      </Typography>
    </Box>
  );
};

export default ReferenceCheckCard;
