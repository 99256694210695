import { styled } from "@mui/material";

// export const CustomCheckbox = styled("input")(({ theme }) => ({
//   width: "16px",
//   height: "16px",
//   cursor: "pointer",
// }));

export const CustomCheckbox = styled("input")(({ theme }) => ({
  width: "16px",
  height: "16px",
  cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    width: "16px",
    height: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "18px",
    height: "18px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "20px",
    height: "20px",
  },
}));
