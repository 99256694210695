import * as Yup from "yup";
import "yup-phone-lite";
import { customValidationForDate } from "./../../utils/customValidationForDate";
import {
  commonDateSchema,
  commonDropdownSchema,
  commonPhoneSchema,
  commonStateProvinceSchema,
  commonStringSchema,
  commonToDateSchema,
} from "../../utils/commonFieldSchema";

export const educationFieldsSchema = {
  nameOfSchoolCollegeUniversity: (isMandatory) =>
    commonStringSchema(
      isMandatory,
      "Name of School/College/University is required",
      100
    ),
  educationStreetAddressLine1: (isMandatory) =>
    commonStringSchema(isMandatory, "Address Line 1 is required", 100),

  educationStreetAddressLine2: (isMandatory) =>
    commonStringSchema(isMandatory, "Address Line 2 is required", 100),
  educationCityTownDistrict: (isMandatory) =>
    commonStringSchema(isMandatory, "City/Town/District is required", 100),
  educationStateProvince: (isMandatory) =>
    commonStateProvinceSchema(isMandatory, "educationCountryId"),

  educationZipPostalAreaCode: (isMandatory) =>
    commonStringSchema(isMandatory, "Zip/Postal/Area Code is required", 100),
  educationCountryId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Country is required"),

  qualificationTypeId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Type Of Qualification is required"),

  specilization: (isMandatory) =>
    commonStringSchema(isMandatory, "Specialization is required", 100),
  educationTypeId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Education Type  is required"),

  certificateNo: (isMandatory) =>
    commonStringSchema(isMandatory, "Certificate No is required"),
  gradePercentageGpaScore: (isMandatory) =>
    commonStringSchema(isMandatory, "Grade Percentage is required"),
  graduationDate: (isMandatory) =>
    Yup.string()
      .nullable()
      .when("isCurrentlyStudying", {
        is: (isCurrentlyStudying) => !isCurrentlyStudying,
        then: (schema) => schema.required("Graduation Date is required"),
      })
      .test({
        name: "customValidationForDate",
        test: customValidationForDate,
        message: "Invalid date format",
        exclusive: true,
      }),
  studentEnrollmentNo: (isMandatory) =>
    commonStringSchema(isMandatory, "Student No/Enrollment No is required"),

  titleOfQualification: (isMandatory) =>
    commonStringSchema(isMandatory, "Title of Qualification is required"),

  educationFromDate: (isMandatory) => commonDateSchema(isMandatory),

  // educationToDate: (isMandatory) =>
  //   commonToDateSchema(isMandatory, "educationFromDate"),
  educationToDate: (isMandatory) =>
    Yup.date()
      .nullable()
      .when(
        ["educationFromDate", "isCurrentlyStudying"],
        (educationFromDate, isCurrentlyStudying) => {
          if (!educationFromDate || !isMandatory || isCurrentlyStudying) return;
          return Yup.date()
            .min(educationFromDate, "To date can't be smaller than From date")
            .required("To Date is required")
            .typeError("To Date is required");
        }
      )
      .test({
        name: "customValidationForDate",
        test: customValidationForDate,
        message: "Invalid date format",
        exclusive: true,
      }),
  qualificationTypeOthers: (isMandatory) =>
    Yup.string()
      .nullable()
      .max(100, "Should not exceed 100 characters")
      .when("qualificationTypeId", {
        is: (qualificationTypeId) => {
          if (qualificationTypeId === 8) {
            return true;
          }
          return false;
        },
        then: (schema) => schema.required("Other Qualification is required"),
      }),
  otherEducationType: (isMandatory) =>
    Yup.string()
      .nullable()
      .max(100, "Should not exceed 100 characters")
      .when("educationTypeId", {
        is: (educationTypeId) => {
          if (educationTypeId === 3) {
            return true;
          }
          return false;
        },
        then: (schema) =>
          schema
            .required("Other Education Type is required")
            .typeError("Other Education Type is required"),
      }),
  educationAdditionalComments: (isMandatory) =>
    commonStringSchema(isMandatory, "Field is required", 250),
  institutePhoneNumber: (isMandatory) => commonPhoneSchema(isMandatory),
  isCurrentlyStudying: (isMandatory) => Yup.boolean(),
};
