import { createSectionInitialDetailsFields } from "../../utils/createSectionInitialDetailsFields";

export const newReference = (inputFields) => {
  let allFields = {
    fullName: "",
    title: "",
    referanceCompanyName: "",
    professionalRelationshipId: 1,
    city: "",
    referanceCountryId: null,
    email: "",
    referencePhoneNumber: {
      referencePhoneCountryCode: null,
      referencePhoneNumber: "",
    },
    referenceAdditionalComments: "",
  };
  return {
    ...createSectionInitialDetailsFields(inputFields, allFields),
    selectedTab: true,
    isExpanded: true,
  };
};
