import { GridActionsCellItem } from "@mui/x-data-grid";
import SystemAdminTable from "./../common/SystemAdminTable";
import { useSelector } from "react-redux";
import { Box, Button, Chip } from "@mui/material";
import ConfirmationModal from "../../../common/modals/ConfirmationModal";
import { useEffect, useState } from "react";
import {
  getOrgsLists,
  updateOrganizationStatus,
} from "../../../store/actions/systemAdminActions";
import { GET_ORGS } from "../../../store/actions/actionTypes";
import { getCurrentFileNameAndFunction } from "../../../utils/getCurrentFileNameAndFunction";
import { useDispatch } from "react-redux";

const UpdateOrganizationStatus = () => {
  const [approveOrgModal, setApproveOrgModal] = useState(false);
  const [rejectOrgModal, setRejectOrgModal] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const { loading, orgsLists } = useSelector((state) => state.systemAdmin);

  const dispatch = useDispatch();

  useEffect(() => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "useEffect"
    );

    dispatch(getOrgsLists(logDetails, { hrOrganizationStatus: 1 }));

    return () => {
      dispatch({
        type: GET_ORGS,
        payload: [],
      });
    };
  }, []);

  const handleUpdateOrgStatus = (updatedStatusValue, toUpdateStatus) => {
    toUpdateStatus && handleDispatchUpdateOrgStatusAction(updatedStatusValue);
    setRejectOrgModal(false);
    setApproveOrgModal(false);
  };

  const handleDispatchUpdateOrgStatusAction = (status) => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleDispatchUpdateOrgStatusAction"
    );
    dispatch(updateOrganizationStatus(selectedOrgId, status, logDetails));
  };

  const columns = [
    {
      field: "hrOrganizationName",
      headerName: "ORGANIZATION NAME",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "hrOrganizationStatus",
      headerName: "ORGANIZATION STATUS",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let backgroundColor =
          params.row.hrOrganizationStatus === "REJECTED"
            ? "#FFE8E8"
            : "#FFF6E1";
        let color =
          params.row.hrOrganizationStatus === "REJECTED"
            ? "#F50808"
            : "#F59008";

        return (
          <Box
            px={1}
            py={0.5}
            borderRadius="10px"
            sx={{
              backgroundColor,
              color,
              border: `1px solid ${color}`,
            }}
          >
            {params.row.hrOrganizationStatus}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "UPDATE STATUS",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          width="100%"
          p={1}
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ transform: "scale(0.8)" }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setApproveOrgModal(true);
              setSelectedOrgId(params.row.hrOrganizationsId);
            }}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setRejectOrgModal(true);
              setSelectedOrgId(params.row.hrOrganizationsId);
            }}
            color="error"
            disabled={params.row.hrOrganizationStatus === "REJECTED"}
          >
            Reject
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <>
      <SystemAdminTable
        columns={columns}
        rows={(orgsLists || [])?.filter((curr) => curr?.hrOrganizationsId)}
        rowId="hrOrganizationsId"
        loading={loading}
      />

      {approveOrgModal && (
        <ConfirmationModal
          title="Approve Selected Organization"
          description="Are you sure you want to approve this organization?"
          handleClose={(status) => handleUpdateOrgStatus("APPROVED", status)}
          open={approveOrgModal}
          confirmBtn="Approve"
        />
      )}

      {rejectOrgModal && (
        <ConfirmationModal
          title="Reject Selected Organization"
          description="Are you sure you want to reject this organization?"
          handleClose={(status) => handleUpdateOrgStatus("REJECTED", status)}
          open={rejectOrgModal}
          confirmBtn="Reject"
        />
      )}
    </>
  );
};

export default UpdateOrganizationStatus;
