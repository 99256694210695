import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ConfirmationModal from "../../../common/modals/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PreviewIcon from "@mui/icons-material/Preview";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HistoryIcon from "@mui/icons-material/History";
import { getTimestampFromString } from "../../../utils/DateTimeHelper";
import ProcessStatus from "./Cells/ProcessStatus";
import VerificationResult from "./Cells/VerificationResult";
import CheckStatusPreview from "./Cells/CheckStatusPreview";

import { Box, Chip, Typography } from "@mui/material";
import permissionKey from "../../constants/permissionKey";
import {
  getCustomPackages,
  sendActivationEmailToCandidate,
  terminateStatusOfCandidate,
} from "../../../store/actions/hrActions";
import { getLoggedInUserHrOrganizationId } from "../../../utils/UserHelper";
import PdfReportModal from "./Modals/PdfReportModal";
import CustomTooltip from "../../common/CustomTooltip";
import CandidateHistoryModal from "./Modals/CandidateHistoryModal";
import { EditOutlined } from "@mui/icons-material";
import SwitchAccountInOpsModal from "../../Operations/OperationsCandidates/OperationsCaseManagement/components/innerComponents/CMCheckStatus/components/SwitchAccountInOpsModal";
import LoginIcon from "@mui/icons-material/Login";
import SendIcon from "@mui/icons-material/Send";
import { getCurrentFileNameAndFunction } from "../../../utils/getCurrentFileNameAndFunction";
import UpdateTableRowValue from "./../../Operations/OperationsCandidates/components/innerComponents/UpdateTableRowValue";
import DisplayingTabularDataUsingCards from "../../common/DisplayingTabularDataUsingCards";

const CandidatesTable = ({ onFetchCandidates, hrTableSortableColumnsRef }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [openModal, setOpenModal] = useState(false);
  const [candidateCaseId, setCandidateCaseId] = useState();
  const [pdfReportModal, setPdfReportModal] = useState(false);
  const [candidateHistoryModal, setCandidateHistoryModal] = useState(false);
  const [selectedCandidateHistory, setSelectedCandidateHistory] =
    useState(null);
  const [selectedCandidatesLatestReport, setSelectedCandidatesLatestReport] =
    useState(null);
  const [page, setPage] = useState(1);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [emailConfirmationModal, setEmailConfirmationModal] = useState(false);
  const [updateTeam, setUpdateTeam] = useState({});

  const {
    loading,
    candidatesLists,
    totalCandidateCaseCount,
    allCustomPackages,
  } = useSelector((state) => state.hr);

  const { loadingTeamTableCellByOrgId } = useSelector(
    (state) => state.operations
  );

  let vendorUser =
    JSON.parse(
      JSON.parse(localStorage.getItem("first_login"))?.CheckMinistryUser
    )?.subRoleId === 11;

  const loadCustomPackagesData = () => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "loadCustomPackagesData"
    );

    dispatch(
      getCustomPackages(
        {
          orgId: getLoggedInUserHrOrganizationId(),
        },
        logDetails
      )
    );
  };

  useEffect(() => !allCustomPackages?.length && loadCustomPackagesData(), []);

  useEffect(() => {
    setPage(+searchParams.get("pageNumber"));
  }, [searchParams.get("pageNumber")]);

  const handleClickOpenModal = (data) => {
    setCandidateCaseId(data);
    setOpenModal(true);
  };

  const handleCloseModal = async (isDelete) => {
    if (isDelete) {
      let params = {
        orgId: getLoggedInUserHrOrganizationId(),
        candidateCaseId,
      };

      let body = {
        verificationProcessId: 8,
      };

      let logDetails = getCurrentFileNameAndFunction(
        import.meta.url,
        "handleCloseModal"
      );

      dispatch(
        terminateStatusOfCandidate(params, body, onFetchCandidates, logDetails)
      );
    }
    setOpenModal(false);
    setCandidateCaseId(null);
  };

  const sendActivationEmail = async (isDelete) => {
    if (isDelete) {
      let params = {
        orgId: getLoggedInUserHrOrganizationId(),
        candidateCaseId,
      };

      let logDetails = getCurrentFileNameAndFunction(
        import.meta.url,
        "sendActivationEmail"
      );

      dispatch(
        sendActivationEmailToCandidate(
          params,
          onFetchCandidates,
          logDetails,
          () => {
            setEmailConfirmationModal(false);
            setCandidateCaseId(null);
          }
        )
      );
    } else {
      setEmailConfirmationModal(false);
      setCandidateCaseId(null);
    }
  };

  const checkCandidateActionPermissions = (actionName, permissions) => {
    let hasAccess = permissions?.find(
      (permission) => permission?.actionName === actionName
    );
    if (hasAccess) {
      return true;
    }
    return false;
  };

  const columnActionArray = (rowData) => {
    let tempActionArray = [];

    tempActionArray.push({
      icon: (
        <CustomTooltip title="Update Package">
          <EditOutlined color="orange" />
        </CustomTooltip>
      ),

      onClick: () => {
        navigate(
          `/hr/candidates/edit?updatePackage=${true}&candidateCaseId=${
            rowData?.candidatesCasesId
          }`
        );
      },
    });

    tempActionArray.push({
      icon: (
        <CustomTooltip title="View Activity History">
          <HistoryIcon
            color={`${
              rowData?.candidateCaseAuditData?.length ? "info" : "disabled"
            }`}
          />
        </CustomTooltip>
      ),
      onClick: () => {
        setCandidateHistoryModal(true);
        setSelectedCandidateHistory(rowData.candidateCaseAuditData);
      },
      disabled: !rowData?.candidateCaseAuditData?.length,
    });

    checkCandidateActionPermissions(
      permissionKey.hrCandidateViewVerification,
      rowData?.permissions
    ) &&
      tempActionArray.push({
        icon: (
          <CustomTooltip title="View Candidate Data">
            <PreviewIcon color="primary" />
          </CustomTooltip>
        ),
        onClick: () =>
          navigate(`/hr/candidates/view/${rowData.candidatesCasesId}`),
      });

    checkCandidateActionPermissions(
      permissionKey.hrCandidateViewReportPdf,
      rowData?.permissions
    ) &&
      tempActionArray.push({
        icon: (
          <CustomTooltip title="View Verification Results">
            <PictureAsPdfIcon
              color={`${rowData?.latestReport?.length ? "error" : "disabled"}`}
            />
          </CustomTooltip>
        ),
        onClick: () => {
          setPdfReportModal(true);
          setSelectedCandidatesLatestReport(rowData.latestReport);
        },
        disabled: !rowData?.latestReport?.length,
      });

    checkCandidateActionPermissions(
      permissionKey.hrCandidateTerminateVerification,
      rowData?.permissions
    ) &&
      tempActionArray.push({
        icon: (
          <CustomTooltip title="Terminate Verification">
            <HighlightOffIcon color="error" />
          </CustomTooltip>
        ),
        onClick: () => handleClickOpenModal(rowData.candidatesCasesId),
        disabled: rowData.packageType === "SYSTEM",
      });

    checkCandidateActionPermissions(
      permissionKey.loginAsCandidate,
      rowData?.permissions
    ) &&
      localStorage.getItem("loginProfile") === "first" &&
      tempActionArray.push({
        icon: (
          <CustomTooltip title="Login into candidate">
            <LoginIcon color="info" />
          </CustomTooltip>
        ),

        onClick: () => {
          setOpenLoginModal(true);
          setCandidateCaseId(rowData?.candidatesCasesId);
        },
      });

    tempActionArray.push({
      icon: (
        <CustomTooltip title="Re-send Activation Email">
          <SendIcon
            color={`${
              rowData?.verificationProcessId !== 1 ? "disabled" : "info"
            }`}
          />
        </CustomTooltip>
      ),
      disabled: rowData?.verificationProcessId !== 1,

      onClick: () => {
        setEmailConfirmationModal(true);
        setCandidateCaseId(rowData?.candidatesCasesId);
      },
    });

    return tempActionArray;
  };

  const columns = [
    {
      headerName: "VERIFICATION RESULT",
      field: "verificationResultStatusName",
      sortable: true,
      renderCustomValue: ({ verificationResultStatusName }) => (
        <Box
          sx={{
            transform: "scale(0.75)",
            marginLeft: verificationResultStatusName ? "-10px" : "0",
          }}
        >
          <VerificationResult
            verificationResultStatusName={verificationResultStatusName}
            displayName={true}
          />
        </Box>
      ),
    },
    {
      headerName: "PROCESS STATUS",
      field: "verificationProcessStatusName",
      sortable: true,
      renderCustomValue: ({ verificationProcessStatusName }) => (
        <Box sx={{ transform: "scale(0.75)", marginLeft: "-10px" }}>
          <ProcessStatus processStatus={verificationProcessStatusName || {}} />
        </Box>
      ),
    },
    // {
    //   headerName: "CHECK STATUS PREVIEW",
    //   sortable: false,
    //   renderCustomValue: (rowData) => (
    //     <Box sx={{ transform: "scale(0.75)", marginLeft: "-15px" }}>
    //       <CheckStatusPreview
    //         data={rowData}
    //         assignedChecks={rowData.assignedCheck}
    //         selectedPackageName={rowData?.assignedPackage?.packageName}
    //       />
    //     </Box>
    //   ),
    // },
    {
      headerName: "CANDIDATE NAME",
      field: "candidateName",
      sortable: true,
      renderCustomValue: (rowData) =>
        vendorUser ? (
          <Chip
            label={rowData.candidateName}
            size="small"
            sx={{
              marginLeft: "5px",
              padding: "0 5px",
              fontSize: "10px",
            }}
          />
        ) : (
          <CustomTooltip
            title={
              <>
                {rowData.candidateEmail} <br />{" "}
                {rowData.candidatePhone
                  ? "(" + rowData.candidatePhone + ")"
                  : ""}
              </>
            }
          >
            <Chip
              label={rowData.candidateName}
              size="small"
              sx={{
                marginLeft: "5px",
                padding: "0 5px",
                fontSize: "10px",
              }}
            />
          </CustomTooltip>
        ),
    },

    {
      headerName: "HIRING COUNTRY",
      field: "hiringCountryName",
      sortable: true,
    },

    {
      headerName: "REGISTERED BY",
      field: "userName",
      sortable: true,
    },
    {
      headerName: "TEAM NAME",
      field: "hrTeamName",
      sortable: true,
      renderCustomValue: (rowData) => (
        <UpdateTableRowValue
          data={rowData}
          updateValue={updateTeam}
          setUpdateValue={setUpdateTeam}
          update_id="team"
          loadingTableCellState={loadingTeamTableCellByOrgId}
          fetchCandidatesOnSave={onFetchCandidates}
          tableLoading={loading}
        />
      ),
    },
    {
      headerName: "REG DATE",
      field: "createdAt",
      sortable: true,
      renderCustomValue: ({ createdAt }) => getTimestampFromString(createdAt),
    },
    {
      headerName: "CUSTOM FIELDS",
      sortable: false,
      renderCustomValue: (rowData) => {
        let arr = rowData?.customFieldResponseData?.map(
          (customField) =>
            `${customField?.customFieldName} - ${
              customField?.customFieldResponseText ||
              customField?.customFieldValue ||
              "NA"
            }`
        );
        return (
          <Typography fontSize="10px">
            {arr?.length ? <>{arr.join(", ")}</> : "-"}
          </Typography>
        );
      },
    },
    {
      headerName: "PACKAGE NAME",
      type: "number",
      sortable: false,
      renderCustomValue: (rowData) =>
        rowData?.assignedPackage?.packageName || "Custom Checks",
    },
    {
      headerName: "ACTIONS",
      type: "actions",
      sortable: false,
      getActions: (rowData) => columnActionArray(rowData),
    },
  ];

  hrTableSortableColumnsRef.current = columns.filter(
    (column) => column.sortable
  );

  const handlePageChange = (e, newPage) => {
    if (page !== newPage) {
      setPage(newPage);
      setSearchParams((prevParams) => {
        return new URLSearchParams({
          ...Object.fromEntries(prevParams.entries()),
          pageNumber: newPage,
        });
      });
    }
  };

  const handleSortingApiCall = (field, sort) => {
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        //when empty string is sent , the api call will fetch the initial values which were not sorted i.e resetting sorting filter
        orderBy: field || "",
        orderDirection: sort || "",
      });
    });
  };

  return (
    <>
      {/* <TableWithCustomPagination
        key="hr-candidates"
        rowId="candidatesCasesId"
        rows={candidatesLists}
        columns={columns}
        pageSize={10}
        page={page}
        loading={loading}
        totalCount={totalCandidateCaseCount}
        handlePageChange={handlePageChange}
        handleSortingApiCall={handleSortingApiCall}
        customSorting={true}
      /> */}
      <DisplayingTabularDataUsingCards
        key="hr-candidates"
        rowId="candidatesCasesId"
        headingFields={["caseNumber", "candidateName", "candidateEmail"]}
        rows={candidatesLists}
        columns={columns}
        pageSize={10}
        page={page}
        loading={loading}
        totalCount={totalCandidateCaseCount}
        handlePageChange={handlePageChange}
      />
      <ConfirmationModal
        title="Terminate process"
        description="Are you sure you want to terminate this candidate's process?"
        handleClose={handleCloseModal}
        open={openModal}
        confirmBtn="Terminate"
      />
      {pdfReportModal && (
        <PdfReportModal
          open={pdfReportModal}
          handleClose={() => setPdfReportModal(false)}
          latestReport={selectedCandidatesLatestReport}
        />
      )}
      {candidateHistoryModal && (
        <CandidateHistoryModal
          open={candidateHistoryModal}
          handleClose={() => setCandidateHistoryModal(false)}
          candidateHistory={selectedCandidateHistory}
        />
      )}
      {openLoginModal ? (
        <SwitchAccountInOpsModal
          open={openLoginModal}
          handleClose={() => {
            setOpenLoginModal(false);
            setCandidateCaseId(null);
          }}
          rows={candidatesLists}
          selectedCandidate={candidateCaseId}
          switchAccountInto="Vendor"
        />
      ) : null}
      {emailConfirmationModal ? (
        <ConfirmationModal
          title="Re-send Activation Email"
          description="Are you sure you want to send activation email again?"
          handleClose={sendActivationEmail}
          open={emailConfirmationModal}
          confirmBtn="Send"
        />
      ) : null}
    </>
  );
};

export default CandidatesTable;
