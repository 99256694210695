import * as Yup from "yup";
import moment from "moment";
import "yup-phone-lite";
import { customValidationForDate } from "../../utils/customValidationForDate";
import {
  commonDateSchema,
  commonDropdownSchema,
  commonEmailSchema,
  commonPhoneSchema,
  commonStateProvinceSchema,
  commonStringSchema,
  commonToDateSchema,
} from "../../utils/commonFieldSchema";

const bonusDataSchema = Yup.object().shape({
  bonusCurrencyId: commonDropdownSchema(true, "Currency is required"),
  bonusAmount: Yup.number()
    .min(1, "Please enter a positive amount")
    .max(Number.MAX_SAFE_INTEGER, "Please enter a practical amount")
    .required("Bonus Amount is required")
    .typeError("Bonus Amount is required"),
  bonusTypeId: commonDropdownSchema(true, "Bonus Type is required"),
  otherBonusTypeName: Yup.string()
    .nullable()
    .max(50, "Should not exceed 50 characters")
    .when("bonusTypeId", {
      is: (bonusTypeId) => {
        if (bonusTypeId === 3) {
          return true;
        }
        return false;
      },
      then: (schema) =>
        schema
          .required("Other Bonus Type is required")
          .typeError("Other Bonus Type is required"),
      otherwise: Yup.string().nullable(),
    }),
});

export const empFieldsSchema = {
  jobTitle: (isMandatory) =>
    commonStringSchema(isMandatory, "Job Title is required"),
  reasonForLeaving: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Reason For Leaving is required"),

  otherReasonForLeaving: (isMandatory) =>
    Yup.string()
      .nullable()
      .when("reasonForLeaving", {
        is: (reasonForLeaving) => {
          if (reasonForLeaving === 8) {
            return true;
          }
          return false;
        },
        then: (schema) =>
          schema
            .required("Other Reason For Leaving is required")
            .typeError("Other Reason For Leaving is required"),
        otherwise: Yup.string().nullable(),
      }),
  employmentCompanyName: (isMandatory) =>
    commonStringSchema(isMandatory, "Company Name is required"),
  employmentFromDate: (isMandatory) => commonDateSchema(isMandatory),
  // employmentToDate: (isMandatory) =>
  //   commonToDateSchema(isMandatory, "employmentFromDate"),
  employmentToDate: (isMandatory) =>
    Yup.date()
      .nullable()
      .when(
        ["employmentFromDate", "isCurrentEmployer"],
        (employmentFromDate, isCurrentEmployer) => {
          if (!employmentFromDate || !isMandatory || isCurrentEmployer) return;
          return Yup.date()
            .min(employmentFromDate, "To date can't be smaller than From date")
            .required("To Date is required")
            .typeError("To Date is required");
        }
      )
      .test({
        name: "customValidationForDate",
        test: customValidationForDate,
        message: "Invalid date format",
        exclusive: true,
      }),
  cessationDate: (isMandatory) =>
    Yup.string()
      .nullable()
      .when("canContactEmployer", {
        is: (canContactEmployer) => {
          if (!canContactEmployer) {
            return true;
          }
          return false;
        },
        then: (schema) =>
          schema
            .required("Cessation Date is required")
            .typeError("Cessation Date is required")
            .test({
              name: "customValidationForDate",
              test: customValidationForDate,
              message: "Invalid date format",
              exclusive: true,
            }),
      }),
  reasonOfChoosingLateCessationDate: (isMandatory) =>
    Yup.string()
      .nullable()
      .max(100, "Should not exceed 100 characters")
      .when(["cessationDate", "canContactEmployer"], {
        is: (cessationDate, canContactEmployer) => {
          let currentDate = moment();
          let tempCessationDate = moment(cessationDate);
          let diff = tempCessationDate.diff(currentDate, "days");

          if (diff >= 30 && !canContactEmployer) {
            return true;
          } else {
            return false;
          }
        },
        then: (schema) =>
          schema
            .required("Reason Of Choosing Late Cessation Date is required")
            .typeError("Reason Of Choosing Late Cessation Date is required"),
      }),
  employmentDepartment: (isMandatory) =>
    commonStringSchema(isMandatory, "Department is required"),
  employeeId: (isMandatory) =>
    commonStringSchema(isMandatory, "Employee ID is required"),
  employerStreetAddressLine1: (isMandatory) =>
    commonStringSchema(isMandatory, "Address Line 1 is required", 100),
  employerStreetAddressLine2: (isMandatory) =>
    commonStringSchema(isMandatory, "Address Line 2 is required", 100),
  employerCityTownDistrict: (isMandatory) =>
    commonStringSchema(isMandatory, "City/Town/District is required"),
  employerZipPostalAreaCode: (isMandatory) =>
    commonStringSchema(isMandatory, "Zip/Postal/Area Code is required"),
  employerCountryId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Country is required"),

  employerPhoneNumber: (isMandatory) => commonPhoneSchema(isMandatory),

  employmentStatusId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Employment Status is required"),

  employerStateProvince: (isMandatory) =>
    commonStateProvinceSchema(isMandatory, "employerCountryId"),

  employmentTypeId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Employment Type is required"),

  wasBonusReceived: (isMandatory) => Yup.boolean(),
  bonus: (isMandatory) =>
    Yup.mixed().when("wasBonusReceived", {
      is: true,
      then: Yup.array().of(bonusDataSchema),
    }),
  wasResignationRequested: (isMandatory) =>
    Yup.boolean()
      .nullable()
      .when("reasonForLeaving", {
        is: (reasonForLeaving) => {
          if (reasonForLeaving === 5) {
            return true;
          }
          return false;
        },
        then: (schema) => schema.typeError("Field is required"),
      }),
  reasonOfResignationRequested: (isMandatory) =>
    Yup.mixed().when("wasResignationRequested", {
      is: true,
      then: Yup.string()
        .nullable()
        .required("Resignation Reason is required")
        .typeError("Resignation Reason is required"),
    }),
  supervisorName: (isMandatory) =>
    commonStringSchema(isMandatory, "Supervisor Name is required"),
  supervisorTitle: (isMandatory) =>
    commonStringSchema(isMandatory, "Supervisor Title is required"),
  supervisorEmail: (isMandatory) =>
    commonEmailSchema(isMandatory, "Supervisor Email address is required"),

  supervisorPhoneNumber: (isMandatory) => commonPhoneSchema(isMandatory),

  hrName: (isMandatory) =>
    commonStringSchema(isMandatory, "HR Name is required"),
  hrTitle: (isMandatory) =>
    commonStringSchema(isMandatory, "HR Title is required"),
  hrEmail: (isMandatory) =>
    commonEmailSchema(isMandatory, "HR Email address is required"),

  hrPhoneNumber: (isMandatory) => commonPhoneSchema(isMandatory),
  salaryAmount: (isMandatory) =>
    Yup.number()
      .nullable()
      .min(1, "Salary can't be less than or equal zero.")
      .max(Number.MAX_SAFE_INTEGER, "Please enter a practical amount")
      .when([], {
        is: () => isMandatory,
        then: (schema) => schema.required("Salary Amount is required"),
      }),
  salaryCurrencyId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Salary Currency is required"),

  salaryFrequencyId: (isMandatory) =>
    commonDropdownSchema(isMandatory, "Salary Frequency is required"),

  otherSalaryFrequency: (isMandatory) =>
    Yup.string()
      .nullable()
      .max(50, "Should not exceed 50 characters")
      .when("salaryFrequencyId", {
        is: (salaryFrequencyId) => {
          if (salaryFrequencyId === 6) {
            return true;
          }
          return false;
        },
        then: (schema) =>
          schema
            .required("Other Salary Frequency is required")
            .typeError("Other Salary Frequency is required"),
      }),
  otherRemuneration: (isMandatory) =>
    commonStringSchema(isMandatory, "Other Remuneration is required"),
  wasOnPayrollOfAgency: (isMandatory) => Yup.boolean(),
  agencyName: (isMandatory) =>
    Yup.mixed().when("wasOnPayrollOfAgency", (v) => {
      if (!v) return;
      return commonStringSchema(true, "Agency Name is required");
    }),
  agencyStreetAddressLine1: (isMandatory) =>
    Yup.mixed().when("wasOnPayrollOfAgency", {
      is: true,
      then: commonStringSchema(
        true,
        "Agency Street Address Line 1 is required",
        100
      ),
    }),
  agencyStreetAddressLine2: (isMandatory) =>
    Yup.string().nullable().max(100, "Should not exceed 100 characters"),
  agencyCityTownDistrict: (isMandatory) =>
    Yup.mixed().when("wasOnPayrollOfAgency", {
      is: true,
      then: commonStringSchema(
        true,
        "Agency City Town District is required",
        100
      ),
    }),
  agencyCountryId: (isMandatory) =>
    Yup.mixed().when("wasOnPayrollOfAgency", {
      is: true,
      then: commonDropdownSchema(true, "Agency Country is required"),
    }),
  agencyStateProvince: (isMandatory) =>
    Yup.mixed().when("wasOnPayrollOfAgency", {
      is: true,
      then: Yup.string()
        .max(100, "Should not exceed 100 characters")
        .nullable()
        .when("agencyCountryId", {
          is: (agencyCountryId) => {
            if (agencyCountryId === 101 || agencyCountryId === 132) {
              return false;
            }
            return true;
          },
          then: (schema) =>
            schema
              .required("Agency State Province is required")
              .typeError("Field is required"),
        }),
    }),
  agencyPhoneNumber: (isMandatory) => commonPhoneSchema(false),
  agencyZipPostalAreaCode: (isMandatory) => commonStringSchema(false, "", 100),
};

export const empGapFieldsSchema = (checkGap) => ({
  reasonOfGapId: (isMandatory) =>
    commonDropdownSchema(checkGap && isMandatory, "Reason is required"),

  employmentAdditionalComments: (isMandatory) =>
    Yup.string()
      .nullable()
      .max(250, "Should not exceed 250 characters")
      .when("reasonOfGapId", {
        is: (reasonOfGapId) => {
          if (reasonOfGapId === 3) {
            return true;
          }
          return isMandatory;
        },
        then: (schema) =>
          schema
            .required("Additional Comment is required")
            .typeError("Additional Comment is required"),
      }),
});
