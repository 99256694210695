import { Box, Grid, Paper, Popper, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PersonAdd, Add } from "@mui/icons-material";

const CandidateCreationOptionsComponent = ({
  handleModalOpen,
  disabled,
  handleRegisterCandidate,
}) => {
  const [displayBtns, setDisplayBtns] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  return (
    // <Grid
    //   item
    //   xs={12}
    //   sm={1}
    //   display="flex"
    //   justifyContent="flex-end"
    //   onMouseLeave={() => {
    //     setDisplayBtns(false);
    //     setAnchorEl(null);
    //   }}
    // >
    <Box
      display="flex"
      onMouseLeave={() => {
        setDisplayBtns(false);
        setAnchorEl(null);
      }}
    >
      <Box
        variant="contained"
        onMouseEnter={(e) => {
          setDisplayBtns(true);
          setAnchorEl(e.currentTarget);
        }}
        px={1}
        pt={0.25}
        sx={{
          border: (theme) =>
            disabled
              ? `1px solid ${theme.palette.grey[300]}`
              : `1px solid ${theme.palette.primary.main}`,
          background: (theme) =>
            disabled ? theme.palette.grey[300] : theme.palette.primary.main,
          borderRadius: "8px",
          cursor: "pointer",
          pointerEvents: disabled ? "none" : "auto",
        }}
      >
        <PersonAdd sx={{ color: "#fff" }} />
      </Box>
      <Popper
        disablePortal={disabled ?? false}
        open={displayBtns}
        anchorEl={anchorEl}
        placement="bottom"
      >
        <Paper
          sx={{
            minWidth: 50,
            background: (theme) => theme.palette.grey[100],

            border: (theme) => `1px solid ${theme.palette.grey[400]}`,
            borderRadius: "5px",
            display: "flex",
            gap: "5px",
            alignItems: "flex-start",
            flexDirection: "column",
            padding: "5px",
          }}
        >
          {" "}
          <Typography
            display="flex"
            alignItems="center"
            fontSize="12px"
            fontWeight={550}
            onClick={
              !handleRegisterCandidate
                ? () => navigate("/hr/candidates/create")
                : handleRegisterCandidate
            }
            sx={{
              width: "100%",
              padding: "5px",
              borderRadius: "5px",
              cursor: "pointer",
              "&:hover": {
                background: "#fff",
              },
            }}
          >
            <Add /> Candidate
          </Typography>
          <Typography
            display="flex"
            alignItems="center"
            fontSize="12px"
            fontWeight={550}
            onClick={handleModalOpen}
            sx={{
              width: "100%",
              padding: "5px",
              borderRadius: "5px",
              cursor: "pointer",
              "&:hover": {
                background: "#fff",
              },
            }}
          >
            <Add /> Multiple Candidates
          </Typography>
        </Paper>
      </Popper>
    </Box>
    // </Grid>
  );
};

export default CandidateCreationOptionsComponent;
