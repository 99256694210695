import { FastField, Field, FieldArray } from "formik";
import DisplayHeadingForMultipleFields from "../../common/DisplayHeadingForMultipleFields";
import {
  Box,
  Grid,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  BaseCountrySelect,
  BaseDatePicker,
  BaseSelect,
  BaseSwitchCard,
  BaseTextField,
} from "../../../base";
import { PhoneNumberTextField } from "../../../../common/Form/PhoneNumberTextField/PhoneNumberTextField";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  InfoOutlined,
  InsertDriveFileOutlined,
} from "@mui/icons-material";
import CustomTooltip from "../../../common/CustomTooltip";
import BaseUploadNew from "../../../base/BaseUploadNew";
import { addAndRemoveImageHanlderArray } from "../../utils/addAndRemoveImageHandlerArray";
import { useDispatch } from "react-redux";
import {
  checkIfFieldToBeDisplayedOrNot,
  getDynamicFieldLabelName,
} from "../../utils/sectionFieldsAndLabelsUtils";
import { useMemo } from "react";
import { toDisplayProgramDetailsSubSection } from "../utils/toDisplayProgramDetailsSubSection";

const EducationFormDetails = ({
  form,
  inputFields,
  pushNewEduRef,
  educationForm,
  qualificationTypes,
  educationTypes,
  setDisableBtn,
  attachFileErr,
  setAttachFileErr,
  sectionData,
}) => {
  const dispatch = useDispatch();

  let isProgramDetailsfieldsPresent = useMemo(
    () => toDisplayProgramDetailsSubSection(inputFields),
    [inputFields]
  );

  return (
    <FieldArray name="qualifications" id="qualifications">
      {({ push, remove }) => {
        pushNewEduRef.current = push;
        return (
          <>
            {form.values.qualifications?.length ? (
              <>
                {form.values.qualifications.map((qualification, index) =>
                  qualification.selectedTab ? (
                    <>
                      <DisplayHeadingForMultipleFields
                        index={index}
                        heading="Education Qualification"
                        currentValue={qualification}
                        sectionValues={form?.values?.qualifications}
                        fieldArrayName="qualifications"
                        setFieldValue={form.setFieldValue}
                      />

                      <Grid container px={3} spacing={2}>
                        {checkIfFieldToBeDisplayedOrNot(
                          "educationStreetAddressLine1",
                          inputFields
                        ) && (
                          <Grid item xs={12} md={6}>
                            <FastField
                              component={BaseTextField}
                              name={`qualifications.${index}.educationStreetAddressLine1`}
                              label={getDynamicFieldLabelName(
                                "educationStreetAddressLine1",
                                inputFields
                              )}
                            />
                          </Grid>
                        )}
                        {checkIfFieldToBeDisplayedOrNot(
                          "educationStreetAddressLine2",
                          inputFields
                        ) && (
                          <Grid item xs={12} md={6}>
                            <FastField
                              component={BaseTextField}
                              name={`qualifications.${index}.educationStreetAddressLine2`}
                              label={getDynamicFieldLabelName(
                                "educationStreetAddressLine2",
                                inputFields
                              )}
                            />
                          </Grid>
                        )}
                        {checkIfFieldToBeDisplayedOrNot(
                          "nameOfSchoolCollegeUniversity",
                          inputFields
                        ) && (
                          <Grid item xs={12} md={6}>
                            <FastField
                              component={BaseTextField}
                              name={`qualifications.${index}.nameOfSchoolCollegeUniversity`}
                              label={getDynamicFieldLabelName(
                                "nameOfSchoolCollegeUniversity",
                                inputFields
                              )}
                            />
                          </Grid>
                        )}
                        {checkIfFieldToBeDisplayedOrNot(
                          "educationCityTownDistrict",
                          inputFields
                        ) && (
                          <Grid item xs={12} md={3}>
                            <FastField
                              component={BaseTextField}
                              name={`qualifications.${index}.educationCityTownDistrict`}
                              label={getDynamicFieldLabelName(
                                "educationCityTownDistrict",
                                inputFields
                              )}
                            />
                          </Grid>
                        )}
                        {checkIfFieldToBeDisplayedOrNot(
                          "educationStateProvince",
                          inputFields
                        ) && (
                          <Grid item xs={12} md={3}>
                            <FastField
                              component={BaseTextField}
                              name={`qualifications.${index}.educationStateProvince`}
                              shouldUpdate={() => true}
                              label={`${getDynamicFieldLabelName(
                                "educationStateProvince",
                                inputFields,
                                false
                              )}${
                                form?.values?.qualifications[index]
                                  ?.educationCountryId === 101 ||
                                form?.values?.qualifications[index]
                                  ?.educationCountryId === 132
                                  ? ""
                                  : "*"
                              }`}
                            />
                          </Grid>
                        )}
                        {checkIfFieldToBeDisplayedOrNot(
                          "educationZipPostalAreaCode",
                          inputFields
                        ) && (
                          <Grid item xs={12} md={4}>
                            <FastField
                              component={BaseTextField}
                              name={`qualifications.${index}.educationZipPostalAreaCode`}
                              label={getDynamicFieldLabelName(
                                "educationZipPostalAreaCode",
                                inputFields
                              )}
                            />
                          </Grid>
                        )}
                        {checkIfFieldToBeDisplayedOrNot(
                          "educationCountryId",
                          inputFields
                        ) && (
                          <Grid item xs={12} md={4}>
                            <FastField
                              component={BaseCountrySelect}
                              name={`qualifications.${index}.educationCountryId`}
                              label={getDynamicFieldLabelName(
                                "educationCountryId",
                                inputFields
                              )}
                            />
                          </Grid>
                        )}
                        {checkIfFieldToBeDisplayedOrNot(
                          "institutePhoneNumber",
                          inputFields
                        ) && (
                          <Grid item xs={12} md={4}>
                            <Typography
                              varient="h5"
                              sx={{
                                marginBottom: "4px",
                              }}
                            >
                              {getDynamicFieldLabelName(
                                "institutePhoneNumber",
                                inputFields
                              )}
                            </Typography>
                            <FastField
                              name={`qualifications.${index}.institutePhoneNumber`}
                              codeName={`qualifications.${index}.institutePhoneCountryCode`}
                              placeholder="Your phone number"
                              className="round_phone"
                              component={PhoneNumberTextField}
                            />
                          </Grid>
                        )}
                      </Grid>

                      {/* HR Details	  */}
                      <Box
                        display={
                          isProgramDetailsfieldsPresent ? "block" : "none"
                        }
                      >
                        <Box my={3} px={3}>
                          <Divider />
                        </Box>

                        <Box
                          mt={3}
                          px={3}
                          display={"flex"}
                          alignItems="center"
                          className="space-x-2"
                        >
                          <InsertDriveFileOutlined />
                          <Typography fontSize={"20px"} fontWeight="500">
                            Program / Study Details
                          </Typography>
                        </Box>
                        <Box px={3} mt={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <Grid container spacing={2}>
                                {checkIfFieldToBeDisplayedOrNot(
                                  "qualificationTypeId",
                                  inputFields
                                ) && (
                                  <>
                                    <Grid item xs={12}>
                                      <FastField
                                        component={BaseSelect}
                                        name={`qualifications.${index}.qualificationTypeId`}
                                        label={getDynamicFieldLabelName(
                                          "qualificationTypeId",
                                          inputFields
                                        )}
                                        optionLabel="candidateEduQualificationTypeName"
                                        optionValue="candidateEduQualificationTypesId"
                                        options={qualificationTypes}
                                      />
                                    </Grid>
                                    {qualification?.qualificationTypeId ===
                                    8 ? (
                                      <Grid item xs={12}>
                                        <FastField
                                          component={BaseTextField}
                                          name={`qualifications.${index}.qualificationTypeOthers`}
                                          label="Other Qualification*"
                                        />
                                      </Grid>
                                    ) : null}
                                  </>
                                )}
                                {checkIfFieldToBeDisplayedOrNot(
                                  "titleOfQualification",
                                  inputFields
                                ) && (
                                  <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <FastField
                                      component={BaseTextField}
                                      name={`qualifications.${index}.titleOfQualification`}
                                      label={getDynamicFieldLabelName(
                                        "titleOfQualification",
                                        inputFields
                                      )}
                                    />
                                    <CustomTooltip
                                      style={{
                                        marginLeft: "10px",
                                        marginTop: "16px",
                                      }}
                                      title="eg. Bachelor of Law/Art/Science/Engineering"
                                      tooltipmaxwidth={320}
                                    >
                                      <InfoOutlined />
                                    </CustomTooltip>
                                  </Grid>
                                )}
                                {checkIfFieldToBeDisplayedOrNot(
                                  "specilization",
                                  inputFields
                                ) && (
                                  <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <FastField
                                      component={BaseTextField}
                                      name={`qualifications.${index}.specilization`}
                                      label={getDynamicFieldLabelName(
                                        "specilization",
                                        inputFields
                                      )}
                                    />
                                    <CustomTooltip
                                      style={{
                                        marginLeft: "10px",
                                        marginTop: "16px",
                                      }}
                                      title="eg. Finance/Marketing/Data Science"
                                    >
                                      <InfoOutlined />
                                    </CustomTooltip>
                                  </Grid>
                                )}
                                {checkIfFieldToBeDisplayedOrNot(
                                  "educationTypeId",
                                  inputFields
                                ) && (
                                  <>
                                    <Grid item xs={12}>
                                      <FastField
                                        component={BaseSelect}
                                        name={`qualifications.${index}.educationTypeId`}
                                        label={getDynamicFieldLabelName(
                                          "educationTypeId",
                                          inputFields
                                        )}
                                        optionLabel="educationTypeName"
                                        optionValue="id"
                                        options={educationTypes}
                                        index={index}
                                      />
                                    </Grid>
                                    {qualification?.educationTypeId === 3 ? (
                                      <Grid item xs={12}>
                                        <FastField
                                          component={BaseTextField}
                                          name={`qualifications.${index}.otherEducationType`}
                                          label="Other Education Type*"
                                        />
                                      </Grid>
                                    ) : null}
                                  </>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Grid container spacing={2}>
                                {checkIfFieldToBeDisplayedOrNot(
                                  "isCurrentlyStudying",
                                  inputFields
                                ) && (
                                  <Grid item xs={12}>
                                    <FastField
                                      component={BaseSwitchCard}
                                      name={`qualifications.${index}.isCurrentlyStudying`}
                                      box={{ mt: 1.5 }}
                                      label={getDynamicFieldLabelName(
                                        "isCurrentlyStudying",
                                        inputFields,
                                        false
                                      )}
                                      toDateFieldName={`qualifications.${index}.educationToDate`}
                                      inputFields={inputFields}
                                    />
                                  </Grid>
                                )}
                                {checkIfFieldToBeDisplayedOrNot(
                                  "educationFromDate",
                                  inputFields
                                ) && (
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    // md={
                                    //   qualification?.isCurrentlyStudying
                                    //     ? 12
                                    //     : 6
                                    // }
                                  >
                                    <FastField
                                      component={BaseDatePicker}
                                      name={`qualifications.${index}.educationFromDate`}
                                      label={getDynamicFieldLabelName(
                                        "educationFromDate",
                                        inputFields
                                      )}
                                      views={["year", "month", "day"]}
                                      placeholder="DD-MMM-YYYY"
                                      inputFormat="dd-MMM-yyyy"
                                    />
                                  </Grid>
                                )}
                                {checkIfFieldToBeDisplayedOrNot(
                                  "educationToDate",
                                  inputFields
                                ) && (
                                  <>
                                    {!qualification?.isCurrentlyStudying ? (
                                      <Grid item xs={12} md={6}>
                                        <Field
                                          component={BaseDatePicker}
                                          name={`qualifications.${index}.educationToDate`}
                                          label={getDynamicFieldLabelName(
                                            "educationToDate",
                                            inputFields
                                          )}
                                          views={["year", "month", "day"]}
                                          placeholder="DD-MMM-YYYY"
                                          inputFormat="dd-MMM-yyyy"
                                          presentBtn={
                                            qualification?.isCurrentlyStudying
                                          }
                                        />
                                      </Grid>
                                    ) : (
                                      <Grid item xs={12} md={6} mt={2.5}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked
                                              disabled
                                              icon={<CheckBoxOutlineBlank />}
                                              checkedIcon={<CheckBox />}
                                            />
                                          }
                                          label="Present"
                                        />
                                      </Grid>
                                    )}
                                  </>
                                )}
                                {checkIfFieldToBeDisplayedOrNot(
                                  "gradePercentageGpaScore",
                                  inputFields
                                ) && (
                                  <Grid item xs={12} md={6}>
                                    <FastField
                                      component={BaseTextField}
                                      name={`qualifications.${index}.gradePercentageGpaScore`}
                                      label={getDynamicFieldLabelName(
                                        "gradePercentageGpaScore",
                                        inputFields
                                      )}
                                    />
                                  </Grid>
                                )}
                                {checkIfFieldToBeDisplayedOrNot(
                                  "isCurrentlyStudying",
                                  inputFields
                                ) && (
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    alignSelf="flex-end"
                                  >
                                    <FastField
                                      component={BaseDatePicker}
                                      name={`qualifications.${index}.graduationDate`}
                                      shouldUpdate={() => true}
                                      label={`Graduation Date${
                                        qualification?.isCurrentlyStudying
                                          ? ""
                                          : "*"
                                      }`}
                                      views={["year", "month", "day"]}
                                      placeholder="DD-MMM-YYYY"
                                      inputFormat="dd-MMM-yyyy"
                                    />
                                  </Grid>
                                )}
                                {checkIfFieldToBeDisplayedOrNot(
                                  "certificateNo",
                                  inputFields
                                ) && (
                                  <Grid item xs={12}>
                                    <FastField
                                      component={BaseTextField}
                                      name={`qualifications.${index}.certificateNo`}
                                      label={getDynamicFieldLabelName(
                                        "certificateNo",
                                        inputFields
                                      )}
                                    />
                                  </Grid>
                                )}
                                {checkIfFieldToBeDisplayedOrNot(
                                  "studentEnrollmentNo",
                                  inputFields
                                ) && (
                                  <Grid item xs={12}>
                                    <FastField
                                      component={BaseTextField}
                                      name={`qualifications.${index}.studentEnrollmentNo`}
                                      label={getDynamicFieldLabelName(
                                        "studentEnrollmentNo",
                                        inputFields
                                      )}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                            {checkIfFieldToBeDisplayedOrNot(
                              "educationAdditionalComments",
                              inputFields
                            ) && (
                              <Grid item xs={12}>
                                <FastField
                                  component={BaseTextField}
                                  name={`qualifications.${index}.educationAdditionalComments`}
                                  rows={4}
                                  multiline
                                  label={getDynamicFieldLabelName(
                                    "educationAdditionalComments",
                                    inputFields
                                  )}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Box>

                      {/* Supporting Documents */}
                      {checkIfFieldToBeDisplayedOrNot(
                        "attachmentsDegree",
                        inputFields
                      ) && (
                        <Box>
                          <Box my={3} px={3}>
                            <Divider />
                          </Box>
                          <Box my={3}>
                            <Box
                              px={3}
                              display={"flex"}
                              alignItems="center"
                              className="space-x-2"
                            >
                              <InsertDriveFileOutlined />
                              <Typography fontSize={"20px"} fontWeight="500">
                                Supporting Documents
                                {getDynamicFieldLabelName(
                                  "attachmentsDegree",
                                  inputFields
                                )?.includes("*")
                                  ? "*"
                                  : ""}
                              </Typography>
                            </Box>

                            <BaseUploadNew
                              multiple
                              mx={3}
                              mt={3}
                              label={getDynamicFieldLabelName(
                                "attachmentsDegree",
                                inputFields
                              )}
                              onChange={(files, delObj, delAll) =>
                                addAndRemoveImageHanlderArray({
                                  files,
                                  delObj,
                                  delAll,
                                  form,
                                  refForm: educationForm,
                                  index,
                                  keyForArray: "qualifications",
                                  categoryName: "EDUCATION_DOCS",
                                  dispatch,
                                  setDisableBtn,
                                })
                              }
                              categoryName="EDUCATION_DOCS"
                              id={`accordion${form.values.qualifications.length}`}
                              uploadFiles={qualification?.attachments}
                              form={form}
                              name={`qualifications.${index}.attachments`}
                              checkName={`qualifications.${index}.attachments`}
                              attachFileErr={attachFileErr}
                              setAttachFileErr={setAttachFileErr}
                              key={index}
                              setDisableBtn={setDisableBtn}
                            />
                          </Box>
                        </Box>
                      )}
                    </>
                  ) : null
                )}
                {/* Add Next Education */}
                {/* {sectionData?.section?.validationScope?.noOfEducations >
                educationForm.current?.values?.qualifications?.length ? (
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 2, md: 4 }}
                    my={3}
                    px={3}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      label="That's All, I don't have any other education qualification"
                    />
                  </Stack>
                ) : null} */}
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography align="center" fontWeight={550} p={2}>
                  No Educations Present
                </Typography>
              </Box>
            )}
          </>
        );
      }}
    </FieldArray>
  );
};

export default EducationFormDetails;
