import { useEffect } from "react";
import Box from "@mui/material/Box";
import { Chip } from "@mui/material";
import ProcessStatus from "../../../Candidates/CandidatesTable/Cells/ProcessStatus";
import VerificationResult from "../../../Candidates/CandidatesTable/Cells/VerificationResult";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import CustomTooltip from "../../../common/CustomTooltip";
import TuneIcon from "@mui/icons-material/Tune";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useSearchParams } from "react-router-dom";
import UpdateTableRowValue from "./innerComponents/UpdateTableRowValue";
import { useState } from "react";
import { calculateM1DueDate } from "../utils/calculateM1DueDate";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { InfoOutlined } from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/Login";
import SwitchAccountInOpsModal from "../OperationsCaseManagement/components/innerComponents/CMCheckStatus/components/SwitchAccountInOpsModal";
import PdfReportModal from "../../../Candidates/CandidatesTable/Modals/PdfReportModal";
import DisplayingTabularDataUsingCards from "../../../common/DisplayingTabularDataUsingCards";
import ConfirmationModal from "../../../../common/modals/ConfirmationModal";
import { getCurrentFileNameAndFunction } from "../../../../utils/getCurrentFileNameAndFunction";
import { sendActivationEmailToCandidate } from "../../../../store/actions/hrActions";
import SendIcon from "@mui/icons-material/Send";

const OperationsCandidatesTableUI = ({
  setOpsComponent,
  getFilteredCandidates,
  opsTableSortableColumnsRef,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateAssignee, setUpdateAssignee] = useState({});
  const [updateTeam, setUpdateTeam] = useState({});
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [pdfReportModal, setPdfReportModal] = useState(false);
  const [selectedCandidatesLatestReport, setSelectedCandidatesLatestReport] =
    useState(null);
  const [emailConfirmationModal, setEmailConfirmationModal] = useState(false);
  const [candidateCaseId, setCandidateCaseId] = useState();
  const [hrOrganizationId, setHrOrganizationId] = useState();

  const {
    totalCandidateCaseCount,
    OpsCandidatesList,
    loading,
    opsSubcheckListTableData,
    totalSubChecksCount,
    loadingAssigneeTableCellByOrgId,
    loadingTeamTableCellByOrgId,
  } = useSelector((state) => state.operations);

  useEffect(() => {
    setPage(+searchParams.get("pageNumber"));
  }, [searchParams]);

  let localStorageData = JSON.parse(localStorage.getItem("first_login"));
  let loggedInUserData = JSON.parse(localStorageData?.CheckMinistryUser);

  const columnActionArray = (rowData) => {
    let tempActionArray = [];

    tempActionArray.push(
      // <GridActionsCellItem
      //   icon={
      //     <CustomTooltip title="Case Settings">
      //       <SettingsApplicationsIcon color="primary" />
      //     </CustomTooltip>
      //   }
      //   label="View"
      // />
      {
        icon: (
          <CustomTooltip title="Case Settings">
            <SettingsApplicationsIcon color="primary" />
          </CustomTooltip>
        ),
        onClick: () => {},
      }
    );

    tempActionArray.push({
      icon: (
        <CustomTooltip title="Case Management">
          <TuneIcon color="primary" />
        </CustomTooltip>
      ),
      // label="View"
      onClick: () => {
        let toggledOpsTableView = searchParams.get("toggledOpsTableView");

        let url = `/ops/candidates/case-management?candidatesCasesId=${rowData?.candidatesCasesId}`;

        if (toggledOpsTableView === "checks") {
          url += `&selectedCheckId=${rowData?.candidatesChecksMappingId}&selectedSubCheckId=${rowData?.id}`;
        }

        navigate(url);
      },
    });

    tempActionArray.push({
      icon: (
        <CustomTooltip title="View Verification Report">
          <PictureAsPdfIcon
            color={`${rowData?.latestReport?.length ? "error" : "disabled"}`}
          />
        </CustomTooltip>
      ),
      // label="View"
      onClick: () => {
        setPdfReportModal(true);
        setSelectedCandidatesLatestReport(rowData?.latestReport);
      },
      disabled: !rowData?.latestReport?.length,
    });

    tempActionArray.push({
      icon: (
        <CustomTooltip title="View Candidate Data">
          <VisibilityIcon color="primary" />
        </CustomTooltip>
      ),
      // label="View"
      onClick: () => setOpsComponent(rowData?.candidatesCasesId),
    });

    tempActionArray.push({
      icon: (
        <CustomTooltip title="Login into candidate">
          <LoginIcon
            // color={
            //   OpsCandidatesList?.find(
            //     (curr) =>
            //       curr?.candidatesCasesId === rowData?.candidatesCasesId
            //   )?.createdBy === loggedInUserData?.usersId &&
            //   loggedInUserData?.subRoleId === 6
            //     ? "info"
            //     : "disabled"
            // }
            color={
              OpsCandidatesList?.find(
                (curr) => curr?.candidatesCasesId === rowData?.candidatesCasesId
              )?.createdBy === loggedInUserData?.usersId
                ? "info"
                : "disabled"
            }
          />
        </CustomTooltip>
      ),
      // label="Login"
      onClick: () => {
        setOpenModal(true);
        setCandidateCaseId(rowData?.candidatesCasesId);
      },
      // disabled={
      //   OpsCandidatesList?.find(
      //     (curr) => curr?.candidatesCasesId === rowData?.candidatesCasesId
      //   )?.createdBy !== loggedInUserData?.usersId ||
      //   loggedInUserData?.subRoleId !== 6
      // }
      disabled:
        OpsCandidatesList?.find(
          (curr) => curr?.candidatesCasesId === rowData?.candidatesCasesId
        )?.createdBy !== loggedInUserData?.usersId,
    });

    tempActionArray.push({
      icon: (
        <CustomTooltip title="Re-send Activation Email">
          <SendIcon
            color={`${
              rowData?.verificationProcessId !== 1 ? "disabled" : "info"
            }`}
          />
        </CustomTooltip>
      ),
      disabled: rowData?.verificationProcessId !== 1,
      // label="View"
      onClick: () => {
        setEmailConfirmationModal(true);
        setCandidateCaseId(rowData?.candidatesCasesId);
        setHrOrganizationId(rowData?.hrOrganization?.hrOrganizationsId);
      },
    });

    return tempActionArray;
  };

  const sendActivationEmail = async (isDelete) => {
    if (isDelete) {
      let params = {
        orgId: hrOrganizationId,
        candidateCaseId,
      };

      let logDetails = getCurrentFileNameAndFunction(
        import.meta.url,
        "sendActivationEmail"
      );

      dispatch(
        sendActivationEmailToCandidate(
          params,
          getFilteredCandidates,
          logDetails,
          () => {
            setEmailConfirmationModal(false);
            setCandidateCaseId(null);
            setHrOrganizationId(null);
          }
        )
      );
    } else {
      setEmailConfirmationModal(false);
      setCandidateCaseId(null);
    }
  };

  const candidateColumns = [
    {
      headerName: "VERIFICATION RESULT",
      field: "verificationResultStatusName",
      width: 200,
      align: "center",
      renderCustomValue: (params) => {
        return (
          <VerificationResult
            verificationResultStatusName={
              params.verificationResultStatusName || ""
            }
            displayName={true}
          />
        );
      },
      sortable: true,
    },
    {
      field: "verificationProcessStatusName",
      headerName: "PROCESS STATUS",
      width: 200,
      align: "center",
      renderCustomValue: (params) => (
        <ProcessStatus
          processStatus={params.verificationProcessStatusName || {}}
        />
      ),
      sortable: true,
    },
    {
      field: "candidateName",
      headerName: "CANDIDATE NAME",
      width: 300,
      align: "center",
      renderCustomValue: (params) => (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          sx={{
            //breaks word if they are too long
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
          }}
        >
          <Box mt={0.5} mr={1}>
            <CustomTooltip title={params.candidateEmail}>
              <InfoOutlined />
            </CustomTooltip>
          </Box>

          <Box width="80%">{params.candidateName}</Box>
        </Box>
      ),
      sortable: true,
    },
    {
      field: "userName",
      headerName: "REGISTER BY",
      width: 300,
      align: "center",
      renderCustomValue: (params) => (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          sx={{
            //breaks word if they are too long
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
          }}
        >
          <Box mt={0.5} mr={1}>
            <CustomTooltip title={params?.loginEmail}>
              <InfoOutlined />
            </CustomTooltip>
          </Box>

          <Box width="80%">{params?.userName}</Box>
        </Box>
      ),
      sortable: true,
    },
    {
      field: "packageName",
      headerName: "PACKAGE NAME",
      type: "number",
      width: 300,
      align: "center",
      sortable: false,
      renderCustomValue: (params) => (
        <>{params?.assignedPackage?.packageName || "Custom Checks"}</>
      ),
    },

    {
      field: "hrOrganizationName",
      headerName: "ORG NAME",
      width: 200,
      align: "center",
    },
    {
      field: "assigneeName",
      headerName: "OPS ASSIGNEE",
      type: "number",
      width: 250,
      align: "center",
      renderCustomValue: (params) => {
        let assigneeLists = [];

        params?.hrOrganization?.opsUserMappingData?.forEach((curr) => {
          assigneeLists.push({
            assignee: curr?.opsUserData?.usersId,
            assigneeName: curr?.opsUserData?.userName,
            assigneeEmail: curr?.opsUserData?.loginEmail,
          });
        });
        return (
          <UpdateTableRowValue
            data={params}
            updateValue={updateAssignee}
            setUpdateValue={setUpdateAssignee}
            assigneeLists={assigneeLists}
            update_id="assignee"
            loadingTableCellState={loadingAssigneeTableCellByOrgId}
            fetchCandidatesOnSave={getFilteredCandidates}
            tableLoading={
              loading ||
              (searchParams.get("toggledOpsTableView") === "candidates"
                ? !OpsCandidatesList
                : !opsSubcheckListTableData)
            }
          />
        );
      },
      sortable: true,
    },
    {
      field: "submissionDate",
      headerName: "SUBMISSION DATE",
      type: "number",
      width: 180,
      align: "center",
      sortable: true,
      renderCustomValue: (params) => (
        <>
          {params?.submissionDate
            ? moment(params?.submissionDate).format("DD-MM-YYYY")
            : "-"}
        </>
      ),
    },
    {
      field: "createdAt",
      headerName: "REGISTERED DATE",
      type: "number",
      width: 180,
      align: "center",
      sortable: true,
      renderCustomValue: (params) => (
        <>
          {params?.createdAt
            ? moment(params?.createdAt).format("DD-MM-YYYY")
            : "-"}
        </>
      ),
    },
    {
      field: "dueDate",
      headerName: "DUE DATE",
      type: "number",
      width: 150,
      align: "center",
      sortable: false,
      renderCustomValue: (params) => {
        let result = calculateM1DueDate(params?.submissionDate);
        let dueDate = result?.dueDate;
        let diffInDays = result?.differenceInDays;
        return (
          <>
            {params?.submissionDate ? dueDate : "-"}
            <CustomTooltip title="M1 Due Date">
              <Chip
                label={params?.submissionDate ? diffInDays : "-"}
                size="small"
                sx={{
                  color: diffInDays >= 0 ? "#00C95C" : "#FF989A",
                  backgroundColor: diffInDays >= 0 ? "#D9F9EB" : "#FBDDE2",
                  fontSize: "10px",
                  width: "25px",
                  height: "16px",
                  marginLeft: "5px",
                  marginTop: "-2px",
                  padding: "1px",
                  borderRadius: "1px",
                  "> span": {
                    padding: 0,
                  },
                }}
              />
            </CustomTooltip>
          </>
        );
      },
    },
    {
      field: "hrTeamName",
      headerName: "TEAM NAME",
      width: 250,
      align: "center",
      renderCustomValue: (params) => (
        <UpdateTableRowValue
          data={params}
          updateValue={updateTeam}
          setUpdateValue={setUpdateTeam}
          update_id="team"
          loadingTableCellState={loadingTeamTableCellByOrgId}
          fetchCandidatesOnSave={getFilteredCandidates}
          tableLoading={
            loading ||
            (searchParams.get("toggledOpsTableView") === "candidates"
              ? !OpsCandidatesList
              : !opsSubcheckListTableData)
          }
        />
      ),
      sortable: true,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      type: "actions",
      width: 200,
      align: "center",
      sortable: false,
      getActions: (params) => columnActionArray(params),
    },
  ];

  const checksColumns = [
    {
      headerName: "VERIFICATION RESULT",
      field: "subCheckVerificationResultStatusName",
      width: 200,
      align: "center",
      renderCustomValue: (params) => (
        <VerificationResult
          verificationResultStatusName={
            params.subCheckVerificationResultStatusName || ""
          }
          displayName={true}
        />
      ),
      sortable: true,
    },
    {
      field: "checkName",
      headerName: "CHECK NAME",
      width: 300,
      align: "center",
      sortable: true,
    },
    {
      field: "subCheckDisplayName",
      headerName: "SUB-CHECK",
      width: 350,
      align: "center",
      sortable: true,
    },
    {
      field: "hrOrganizationName",
      headerName: "ORG NAME",
      width: 200,
      align: "center",
    },
    {
      field: "assigneeName",
      headerName: "CHECK ASSIGNEE",
      width: 250,
      align: "center",
      renderCustomValue: (params) => params.assigneeName || "-",
      sortable: true,
    },
    {
      field: "submissionDate",
      headerName: "SUBMISSION DATE",
      type: "number",
      width: 180,
      align: "center",
      renderCustomValue: (params) => (
        <>
          {params?.submissionDate
            ? moment(params?.submissionDate).format("DD-MM-YYYY")
            : "-"}
        </>
      ),
      sortable: true,
    },
    {
      field: "dueDate",
      headerName: "DUE DATE",
      type: "number",
      width: 150,
      align: "center",
      sortable: false,
      renderCustomValue: (params) => {
        let result = calculateM1DueDate(params?.submissionDate);
        let dueDate = result?.dueDate;
        let diffInDays = result?.differenceInDays;
        return (
          <>
            {params?.submissionDate ? dueDate : "-"}
            <CustomTooltip title="M1 Due Date">
              <Chip
                label={params?.submissionDate ? diffInDays : "-"}
                size="small"
                sx={{
                  color: diffInDays >= 0 ? "#00C95C" : "#FF989A",
                  backgroundColor: diffInDays >= 0 ? "#D9F9EB" : "#FBDDE2",
                  fontSize: "10px",
                  width: "25px",
                  height: "16px",
                  marginLeft: "5px",
                  marginTop: "-2px",
                  padding: "1px",
                  borderRadius: "1px",
                  "> span": {
                    padding: 0,
                  },
                }}
              />
            </CustomTooltip>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      type: "actions",
      width: 200,
      align: "center",
      sortable: false,
      getActions: (params) => columnActionArray(params),
    },
  ];

  opsTableSortableColumnsRef.current = (
    searchParams.get("toggledOpsTableView") === "candidates"
      ? candidateColumns
      : checksColumns
  ).filter((column) => column.sortable);

  const handlePageChange = (e, newPage) => {
    if (page !== newPage) {
      setPage(newPage);

      setSearchParams((prevParams) => {
        return new URLSearchParams({
          ...Object.fromEntries(prevParams.entries()),
          pageNumber: newPage,
        });
      });
    }
  };

  const handleSortingApiCall = (field, sort) => {
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        //when empty string is sent , the api call will fetch the initial values which were not sorted i.e resetting sorting filter
        orderBy: field || "",
        orderDirection: sort || "",
      });
    });
  };

  return (
    <>
      {/* <TableWithCustomPagination
        key="ops-candidates"
        rowId={
          searchParams.get("toggledOpsTableView") === "candidates"
            ? "candidatesCasesId"
            : "id"
        }
        rows={
          searchParams.get("toggledOpsTableView") === "candidates"
            ? OpsCandidatesList
            : opsSubcheckListTableData
        }
        columns={
          searchParams.get("toggledOpsTableView") === "candidates"
            ? candidateColumns
            : checksColumns
        }
        pageSize={10}
        page={page}
        loading={
          loading ||
          (searchParams.get("toggledOpsTableView") === "candidates"
            ? !OpsCandidatesList
            : !opsSubcheckListTableData)
        }
        totalCount={
          searchParams.get("toggledOpsTableView") === "candidates"
            ? totalCandidateCaseCount
            : totalSubChecksCount
        }
        handlePageChange={handlePageChange}
        handleSortingApiCall={handleSortingApiCall}
        customSorting={true}
      /> */}
      <DisplayingTabularDataUsingCards
        key="ops-candidates"
        rowId={
          searchParams.get("toggledOpsTableView") === "candidates"
            ? "candidatesCasesId"
            : "id"
        }
        headingFields={["caseNumber", "candidateName", "candidateEmail"]}
        rows={
          searchParams.get("toggledOpsTableView") === "candidates"
            ? OpsCandidatesList
            : opsSubcheckListTableData
        }
        columns={
          searchParams.get("toggledOpsTableView") === "candidates"
            ? candidateColumns
            : checksColumns
        }
        pageSize={10}
        page={page}
        loading={
          loading ||
          (searchParams.get("toggledOpsTableView") === "candidates"
            ? !OpsCandidatesList
            : !opsSubcheckListTableData)
        }
        totalCount={
          searchParams.get("toggledOpsTableView") === "candidates"
            ? totalCandidateCaseCount
            : totalSubChecksCount
        }
        handlePageChange={handlePageChange}
      />
      {openModal &&
      (searchParams.get("toggledOpsTableView") === "candidates"
        ? OpsCandidatesList?.length
        : opsSubcheckListTableData?.length) ? (
        <SwitchAccountInOpsModal
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
            setCandidateCaseId(null);
          }}
          rows={
            searchParams.get("toggledOpsTableView") === "candidates"
              ? OpsCandidatesList
              : opsSubcheckListTableData
          }
          selectedCandidate={candidateCaseId}
        />
      ) : null}
      {pdfReportModal && (
        <PdfReportModal
          open={pdfReportModal}
          handleClose={() => setPdfReportModal(false)}
          latestReport={selectedCandidatesLatestReport}
        />
      )}
      {emailConfirmationModal ? (
        <ConfirmationModal
          title="Re-send Activation Email"
          description="Are you sure you want to send activation email again?"
          handleClose={sendActivationEmail}
          open={emailConfirmationModal}
          confirmBtn="Send"
        />
      ) : null}
    </>
  );
};

export default OperationsCandidatesTableUI;
