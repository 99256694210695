import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const SortByHRCandidatesComponent = ({ sortableColumns }) => {
  const [displayColumnsDropdown, setDisplayColumnsDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSetSortByFilter = (param) => {
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...param,
      });
    });
  };

  return (
    <Box sx={{ display: "flex" }} width={{ xs: "100%", sm: "auto" }}>
      <Box
        onMouseLeave={() => {
          setDisplayColumnsDropdown(false);
          setAnchorEl(null);
        }}
        width={{ xs: "100%", sm: "auto" }}
      >
        <Box
          variant="contained"
          onMouseEnter={(e) => {
            setDisplayColumnsDropdown(true);
            setAnchorEl(e.currentTarget);
          }}
          ref={(el) => {
            el && setAnchorEl(el);
          }}
          width={{ xs: "100%", sm: "auto" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            border: (theme) => `1px solid ${theme.palette.grey[400]}`,
            borderRadius: "5px",
            padding: "7px 2px",
            // width: "fit-content",
          }}
        >
          <Typography component="span" fontWeight={550} fontSize="12px">
            SORT BY:{" "}
            {
              sortableColumns?.find(
                (curr) => curr?.field === searchParams.get("orderBy")
              )?.headerName
            }
          </Typography>
          <ArrowDropDownIcon
            sx={{ transform: displayColumnsDropdown ? "rotate(180deg)" : "" }}
          />
        </Box>
        <Popper
          open={displayColumnsDropdown}
          anchorEl={anchorEl}
          placement="bottom"
        >
          <Paper
            sx={{
              width: anchorEl?.offsetWidth || "auto",
              background: (theme) => theme.palette.grey[100],
              border: (theme) => `1px solid ${theme.palette.grey[400]}`,
              borderRadius: "5px",
              display: "flex",
              gap: "5px",
              alignItems: "flex-start",
              flexDirection: "column",
              padding: "5px",
            }}
          >
            {sortableColumns?.map((columnData) => (
              <Typography
                key={columnData.field}
                fontSize="12px"
                fontWeight={550}
                onClick={() => {
                  handleSetSortByFilter({
                    orderBy: columnData.field,
                    orderDirection: searchParams.get("orderDirection") || "asc",
                  });
                  setDisplayColumnsDropdown(false);
                  setAnchorEl(null);
                }}
                sx={{
                  width: "100%",
                  padding: "5px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#fff",
                  },
                }}
              >
                {columnData?.headerName}
              </Typography>
            ))}
          </Paper>
        </Popper>
      </Box>
      {/* <Grid xs={6} display="flex" justifyContent="flex-start">
        {searchParams.get("orderBy") ? (
          <Chip
            label={
              sortableColumns?.find(
                (curr) => curr?.field === searchParams.get("orderBy")
              )?.headerName
            }
            variant="outlined"
            onDelete={() => {
              handleSetSortByFilter({
                orderBy: "createdAt",
                orderDirection: "desc",
              });
            }}
          />
        ) : null}
      </Grid> */}

      <Box
        // xs={3}
        alignContent="center"
      >
        {searchParams.get("orderDirection") === "asc" ? (
          <ArrowUpwardIcon
            fontSize="small"
            sx={{ ml: 1 }}
            titleAccess="ascending"
            onClick={() =>
              handleSetSortByFilter({
                orderDirection: "desc",
              })
            }
          />
        ) : (
          <ArrowDownwardIcon
            fontSize="small"
            sx={{ ml: 1 }}
            titleAccess="descending"
            onClick={() =>
              handleSetSortByFilter({
                orderDirection: "asc",
              })
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default SortByHRCandidatesComponent;
